<template>
  <div class="baggage" v-if="has_baggage_ancillaries">
    <div class="headline">{{ "Bagage:" | locale }}</div>
    <div class="passengers">
      <div
        class="passenger"
        v-for="bag_itinerary in baggage_ancillaries"
      >
        <div class="passenger_number">{{ translatePax(bag_itinerary, itinerary.attributes.passenger_type) }}</div>

        <div class="leg-container" style="float: left;">
          <div
            v-for="bag_flight in bag_itinerary"
            class="leg" :style="{ display: 'inline-block', width: cabinBagWidth(bag_flight.ancillaries) + 'px' }"
          >
            <template v-for="ancillary in bag_flight.ancillaries">
              <div v-if="['BAG1', 'CABIN'].includes(ancillary.key)" class="ancillary" style="clear: both;">
                <input
                  type="checkbox"
                  :name="fetch_ancillary_id(ancillary)"
                  :id="fetch_ancillary_id(ancillary)"
                  value="1"
                  :data-price="ancillary.price"
                  :data-price-pax="ancillary.price_per_pax"
                  :data-ancillary-type="ancillary.type"
                  v-model="ancillary.selected"
                  @change="$emit('change')"
                >
                <label :for="fetch_ancillary_id(ancillary)">{{ translateBag(bag_flight, ancillary) }}</label>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { translate } from "app/helpers";

const ancillaryText = {
  "BAG":    "Resa %{num}, checkin %{weight}kg +%{amount}",
  "CABIN":  "Resa %{num}, cabin %{weight}kg +%{amount}"
};

export default {
  name: "baggage",
  props: {
    /**
     * The itinerary.
     */
    itinerary: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      /** Cache the ids as we use it quite a lot. */
      ancillary_cache_ids: {}
    };
  },
  computed: {
    /**
     * Returns the baggage object from the itinerary.
     *
     * TODO: In the future this shouldn't have access to itinerary.
     */
    baggage_ancillaries() {
      return this.itinerary.attributes.baggage_ancillaries;
    },
    /**
     * Returns whether we have baggage ancillaries available.
     *
     * @returns {boolean}
     */
    has_baggage_ancillaries() {
      return Object.keys(this.baggage_ancillaries).length !== 0;
    }
  },
  methods: {
    /**
     * Returns the ancillary id.
     *
     * @param ancillary
     * @returns {string}
     */
    fetch_ancillary_id(ancillary) {
      if (typeof this.ancillary_cache_ids[ancillary.id] !== "undefined" || this.ancillary_cache_ids[ancillary.id] === null) {
          return this.ancillary_cache_ids[ancillary.id];
      }
      const uuid = this.itinerary.attributes.uuid;
      return this.ancillary_cache_ids[ancillary.id] = `baggage[${uuid}][${ancillary.id}]`;
    },
    /**
     * Returns the pax header text.
     *
     * @param bag_itinerary
     * @returns {string}
     */
    translatePax(bag_itinerary, type) {
      const firstFlight = bag_itinerary[0];
      let args = [firstFlight.pax_number, translate(firstFlight.pax_type)];
      if (type == "ADT" && firstFlight.pax_type == "YTH") {
        args = [firstFlight.pax_number, translate("ADT")];
      }

      return translate("Resenär %{num}, %{type}", ...args);
    },

    /**
     * Returns the bag text.
     *
     * @param bag_flight
     * @param ancillary
     * @returns {string}
     */
    translateBag(bag_flight, ancillary) {
      const args = [bag_flight.leg_number, ancillary.weight, ancillary.formatted_price];

      return translate(ancillaryText[ancillary.type] || ancillaryText["BAG"], ...args);
    },

    cabinBagWidth(ancillaries) {
      if (ancillaries.some(e => e["key"] == "CABIN")) {
        return 300
      } else {
        return 250
      }
    },
  }
}
</script>
